const ContentPageSingle = (e) => {

    if (!jQuery('body').hasClass('page')) {
        return;
    }

    const backgroundResize = () => {
        // this hero is used on all generic pages
        var background = jQuery('.content-services-hero__outer-wrap');
        // clear any previous changes to background height
        background.css('height','');
        var backgroundHeight = background.height();
        console.log('bh ' + backgroundHeight);

        var cardsWrap = jQuery('.two-card-hero-overlap');

        var cardsHeight = jQuery(cardsWrap).height();
        // console.log('cardsh ' + cardsHeight);

        var lastCardHeight = jQuery('.two-card-hero-overlap__card').last().outerHeight();
        // console.log('lasth' + lastCardHeight);

        var fromBottom = (lastCardHeight / 2) + 100; // 50 to give a little more offset

        var totalHeight = (backgroundHeight + cardsHeight) - fromBottom;
        if (cardsWrap.length) {
            background.height(totalHeight);
            cardsWrap.css('top', -(cardsHeight - fromBottom) + 'px');
            cardsWrap.css('margin-bottom', -(cardsHeight - fromBottom) + 'px');
        }

        // On mobile and tablet, height of hero background is one half the bottom of the lowest card
    };
    
    const init = () => {
        backgroundResize();
    };
    
    return init();
};