const CardSlider = () => {
    const initSliderStandard = () => {
        const tnsSliders = document.querySelectorAll('.card-slider__cards .card-slider__cards-wrap');
		tnsSliders.forEach(slider => {
            const removeBlankBreakpoint = 567;

            if ( jQuery(window).width() < removeBlankBreakpoint ) {
                jQuery('.blank', slider).remove();
            }

            let breakpoint = parseInt(jQuery(slider).parent().parent().attr('data-slide-breakpoint'));
            if ( typeof breakpoint !== 'number' ) {
                breakpoint = 567;
            }
            
            if ( jQuery(window).width() < breakpoint ) {
                return;
            }

            let items = 1;
            const slidesToShow = parseInt(jQuery(slider).parent().parent().attr('data-slides-to-show'));
            if ( typeof slidesToShow === 'number' ) {
                items = slidesToShow;
            }

            const tnsSlider = tns({
                container: slider,
                items: items,
                autoWidth: true,
                autoplay: false,
                nav: false,
                controls: false,
                edgePadding: 20,
                gutter: 38,
                center: false,
            });
            jQuery('.card-slider__cards-prev .btn').click(function() {
                tnsSlider.goTo('prev');
            });
            jQuery('.card-slider__cards-next .btn').click(function() {
                tnsSlider.goTo('next');
            });
        });
    };
    
    const init = () => {
        initSliderStandard();
    };
    
    return init();
};