const ContentHomeHero = () => {

    const slider = () => {

        const tnsSlider = tns({
            mode: 'carousel',
            container: '.content-home-hero__slides',
            items: 1,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayButtonOutput: false,
            nav: true,
            navPosition: 'bottom',
            controls: false,
            edgePadding: 0,
            gutter: 0,
            center: false,
            responsive: {
                769: {
                    autoplay: true,
                }
            }
        });
    };
    
    const init = () => {

        if ( ! jQuery('.content-home-hero__slides').length ) {
            return;
        }

        slider();
    };
    
    return init();
};