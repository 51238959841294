const ContentHomeWhatWeDo = () => {

    const toggle = () => {
        jQuery('.content-home-what-we-do__toggle-item').click(function(e) {
            e.preventDefault();
            if ( jQuery(this).hasClass('selected') ) {
                return;
            }
            jQuery('.content-home-what-we-do__toggle-item').toggleClass('selected');
            jQuery('.content-home-what-we-do').toggleClass('show');
        });
    };
    
    const init = () => {
        toggle();
    };
    
    return init();
};