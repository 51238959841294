const ContentPortfolioHero = () => {

    const slider = () => {

        const imageSlider = tns({
            // mode: 'gallery',
            container: '.content-portfolio-hero__images-slider',
            items: 1,
            autoWidth: false,
            fixedWidth: 600,
            autoplay: true,
            autoplayTimeout: 5000,
            nav: false,
            controls: false,
            edgePadding: 0,
            gutter: 0,
            center: false,
        });

        const contentSlider = tns({
            mode: 'gallery',
            container: '.content-portfolio-hero__content-slider',
            items: 1,
            autoWidth: false,
            autoplay: true,
            autoplayTimeout: 5000,
            nav: false,
            controls: false,
            edgePadding: 0,
            gutter: 0,
            center: false,
        });

        contentSlider.events.on('transitionStart', (info, eventName) => {
            jQuery('.content-portfolio-hero__nav-dots .dot').removeClass('selected');
            setTimeout(() => {
                let next = info.index+1;
                if ( next > info.slideCount) {
                    next = 1;
                }
                jQuery(`.content-portfolio-hero__nav-dots .dot[data-slide-num="${next}"]`).addClass('selected');
            }, 1);
        });

        jQuery('.content-portfolio-hero__nav-next').click(function() {
            imageSlider.goTo('next');
            imageSlider.pause();
            contentSlider.goTo('next');
            contentSlider.pause();
        });
    };
    
    const init = () => {

        if ( ! jQuery('.content-portfolio-hero__content-slider').hasClass('content-portfolio-hero__content-slider') ) {
            return;
        }

        slider();
    };
    
    return init();
};