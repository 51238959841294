const SearchSiteDefault = () => {
    
    const showMenu = () => {
        jQuery('#mobile-search-trigger').click(function(e) {
            e.preventDefault();
            jQuery('.section-site-search-form').addClass('show');
            jQuery('.section-site-search-form input[type="text"]').focus();
            jQuery('body').addClass('pause-scrolling');
        });
    };

    const hideMenu = () => {
        jQuery('.section-site-search-form .close').click(function(e) {
            e.preventDefault();
            jQuery('.section-site-search-form').removeClass('show');
            jQuery('body').removeClass('pause-scrolling');
        });
    };
    
    const init = () => {
        showMenu();
        hideMenu();
    };
    
    return init();
};