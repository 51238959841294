const MenuCta = () => {
    
    const init = () => {
        jQuery('.section-header-mobile__menu-cta, .section-header-desktop__menu-cta').find('li').each(function() {
            const li = jQuery(this);
            const linkText = jQuery('a', li).text();
            const textSpan = `<span class="btn__text">${linkText}</span>`;
            const iconSpan = `<span class="btn__icon">${siteObject.carrot}</span>`;
            jQuery('a', li).html(textSpan + iconSpan);
        });
    };
    
    return init();
};