const ContentServicesDebounce = () => {
    
    const debounce = () => {
        // console.log('bounce bounce');
        let isMobile = window.matchMedia( '(max-width: 575px)' ).matches;
        let mobileToggle = document.querySelectorAll('.content-services__sidebar__mobile-parent');

        if (isMobile) {
            mobileToggle.forEach(function (el) {
                // Only toggle if menu is open
                if (el.classList.contains('content-services__sidebar--mobile-open')) {
                    el.classList.remove('content-services__sidebar--mobile-open');
                    el.classList.add('content-services__sidebar--mobile-closed');
                    // Close all sub menus when main closes
                    let menuToggles = document.querySelectorAll('.content-services__sidebar__parent');
                    menuToggles.forEach(function (el) {
                        el.classList.remove('content-services__sidebar--open');
                        el.classList.add('content-services__sidebar--closed');
                    });
                }
            });
        } else { // larger than mobile
            mobileToggle.forEach(function (el) {
                el.classList.add('content-services__sidebar--mobile-open');
                el.classList.remove('content-services__sidebar--mobile-closed');
            });
        }
    }
    const init = () => {
        if ( ! jQuery('.content-services__sidebar').length ) {
            return;
        }

        debounce();
    };
    
    return init();
};