const ContentSolutionsInternal = () => {

    let lottiePath = jQuery('.content-solutions-single-hero__lottie').data('lottie');

    let lottieContainer = jQuery('.content-solutions-single-hero__lottie')[0];

    const lottieRender = () => {
        lottie.loadAnimation({
            container: lottieContainer,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: siteObject.stylesheet_directory_uri + lottiePath // the path to the animation json
          });
    }
    
    const init = () => {

        if ( ! jQuery('.content-solutions-single-hero__lottie').length ) {
            return;
        }
        console.log(siteObject.stylesheet_directory_uri + lottiePath)
        lottieRender();
    };
    
    return init();
};