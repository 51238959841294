const ContentPortfolioArchivedCaseStudies = () => {

    const onChangeSolution = () => {

        jQuery('.content-portfolio-archived-case-studies__filter-select .btn').click(function(e) {
            e.preventDefault();
            const click = jQuery(this);
            if ( click.hasClass('selected') || click.hasClass('is-selected') ) {
                return;
            }

            jQuery('.content-portfolio-archived-case-studies__filter-select .selected .btn__text').html(jQuery('.btn__text', click).text());
            jQuery('.content-portfolio-archived-case-studies__filter-select .btn').removeClass('is-selected');
            setTimeout(() => {
                click.addClass('is-selected');
            }, 1);

            jQuery('.content-portfolio-archived-case-studies__content').addClass('pause');

            const index = parseInt( click.attr('data-index-selected') );
            const html = `<div class="content-portfolio-archived-case-studies__content-slider">${portfolioArchivedCaseStudiesContentSlides[index]}</div>`;
            setTimeout(() => {
                jQuery('.content-portfolio-archived-case-studies__content').html(html);
            }, 200);
            setTimeout(() => {
                triggerSlider();
                jQuery('.content-portfolio-archived-case-studies__content').removeClass('pause');
            }, 200);

        });

    };

    const triggerSlider = () => {

        const tnsSliders = document.querySelectorAll('.content-portfolio-archived-case-studies__content-slider');
		tnsSliders.forEach(slider => {

            const tnsSlider = tns({
                container: slider,
                items: 1,
                autoWidth: true,
                autoplay: false,
                nav: false,
                controls: false,
                edgePadding: 0,
                gutter: 0,
                center: false,
            });

            jQuery('.content-portfolio-archived-case-studies__filter-nav-total-p').html(tnsSlider.getInfo().slideCount);

            tnsSlider.events.on('transitionStart', (info, eventName) => {
                jQuery('.content-portfolio-archived-case-studies__filter-nav-current-p').html(info.displayIndex);
            });

            jQuery('.content-portfolio-archived-case-studies__filter-nav-prev .btn').click(function() {
                tnsSlider.goTo('next');
            });
            jQuery('.content-portfolio-archived-case-studies__filter-nav-next .btn').click(function() {
                tnsSlider.goTo('prev');
            });
        });

    };
    
    const init = () => {
        
        if ( ! jQuery('.content-portfolio-archived-case-studies__content-slider').hasClass('content-portfolio-archived-case-studies__content-slider') ) {
            return;
        }

        onChangeSolution();
        triggerSlider();
    };
    
    return init();
};