const ContentBuilderCtaJewelDebounce = () => {
    
    const debounce = () => {
        console.log('bounce bounce');
        let jewelCtas = document.querySelectorAll('.content-cta-jewel__wrap');
        jewelCtas.forEach(function (el) {
            let CtaWidth = el.clientWidth;
            let CtaHeight = el.clientHeight;
            let mask = el.querySelector('.content-cta-jewel__mask');
            mask.style.borderBottomWidth = (CtaHeight * 1.4) + 'px';
            mask.style.borderLeftWidth = CtaWidth + 'px';

            //console.log (CtaWidth + ' ' + CtaHeight);
        });
        // let isMobile = window.matchMedia( '(max-width: 575px)' ).matches;

        // Get width and height of container
        // Set bottom and right border


    }
    const init = () => {
        if ( ! jQuery('.content-cta-jewel').length ) {
            return;
        }

        debounce();
    };
    
    return init();
};