const SinglePortfolioRelevantCaseStudies = () => {

    const triggerSlider = () => {

        const tnsSliders = document.querySelectorAll('.single-portfolio-relevant-case-studies__content-slider');
		tnsSliders.forEach(slider => {

            const tnsSlider = tns({
                container: slider,
                items: 1,
                autoWidth: true,
                autoplay: false,
                nav: false,
                controls: false,
                edgePadding: 0,
                gutter: 0,
                center: false,
            });

            jQuery('.single-portfolio-relevant-case-studies__filter-nav-total-p').html(tnsSlider.getInfo().slideCount);

            tnsSlider.events.on('transitionStart', (info, eventName) => {
                jQuery('.single-portfolio-relevant-case-studies__filter-nav-current-p').html(info.displayIndex);
            });

            jQuery('.single-portfolio-relevant-case-studies__filter-nav-prev .btn').click(function() {
                tnsSlider.goTo('next');
            });
            jQuery('.single-portfolio-relevant-case-studies__filter-nav-next .btn').click(function() {
                tnsSlider.goTo('prev');
            });
        });

    };
    
    const init = () => {
        
        if ( ! jQuery('.single-portfolio-relevant-case-studies__content-slider').hasClass('single-portfolio-relevant-case-studies__content-slider') ) {
            return;
        }

        triggerSlider();
    };
    
    return init();
};