const SolutionsSingleServicesNav = () => {

    const initWaypoints = () => {
        jQuery('.content-solutions-single-services').find('.content-waypoint').each(function() {
            const point = jQuery(this);
            const index = point.attr('data-index');

            jQuery(`#nav-${index}`).click(function(e) {
                e.preventDefault();
                jQuery('.content-solutions-single-services__nav .btn').removeClass('selected');
                jQuery('html, body').animate({
                    scrollTop: jQuery(`#group-${index}`).offset().top
                }, 50);
            });

            const currWaypoint = new Waypoint({
                element: `#group-${index}`,
                handler: function(direction) {
                    jQuery('.content-solutions-single-services__nav .btn').removeClass('selected');

                    switch ( direction ) {
                        case 'up':
                            setTimeout(function() {
                                jQuery(`#nav-${index-1}`).toggleClass('selected');
                            }, 1);
                            break;
                        case 'down':
                            setTimeout(function() {
                                jQuery(`#nav-${index}`).toggleClass('selected');
                            }, 1);
                            break;
                    }
                }
            });
        });
    };
    
    const init = () => {
        initWaypoints();
    };
    
    return init();
};