/**
 * modalJs
 **/
var modalJs = {

	fadeInSpeed : 200,
	fadeOutSpeed : 200,

	example: function() {

		// Copy paste this jquery click and add it to your own file
		jQuery( '.target-class-here' ).click(function(e) {
			e.preventDefault();

			jQuery('body').append( modalJs.getHtml() );

			const html = 'Title and content here...';
			jQuery('.modal-js__content').html( html );

			jQuery('.modal-js').fadeIn( modalJs.fadeInSpeed );
			jQuery('.modal-js .close').click( modalJs.closeModal );

			modalJs.activateCloseModal();
			modalJs.pauseScrolling();
		});
	},
	getHtml : function() {
		return '<div id="modal" class="modal-js"><div class="modal-js__wrap"><div class="modal-js__close close"><span class="fa fa-times-circle close"></span></div><div class="modal-js__container"><div class="modal-js__content"></div></div></div></div>';
	},
    activateCloseModal : function() {
		jQuery(document).mousedown(function(e) {
			if ( 'modal' === jQuery(e.target).attr('id') ) {
				modalJs.closeModal();
			} else {
				return;
			}
		});
		jQuery(document).keydown(function(e) {
			switch (e.which) {
				case 27 :
					modalJs.closeModal();
					break;
			}
		});
	},
	closeModal : function() {
		jQuery('#modal').fadeOut( modalJs.fadeOutSpeed, function() {
			modalJs.unPauseScrolling();
			jQuery('#modal').remove();
		});
	},
	pauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'hidden'
		});
	},
	unPauseScrolling : function() {
		jQuery('body').css({
			'overflow' : 'auto'
		});
	}
};
