const ContentServices = () => {
    
    const sidebar = () => {
        let menuToggles = document.querySelectorAll('.content-services__sidebar__parent .btn__icon');
        menuToggles.forEach(function (e) {
            e.addEventListener('click', function(e){
                let parentLi = e.target.parentElement;
                if (parentLi.classList.contains('content-services__sidebar--open')) {
                    parentLi.classList.remove('content-services__sidebar--open');
                    parentLi.classList.add('content-services__sidebar--closed');
                } else {
                    parentLi.classList.remove('content-services__sidebar--closed');
                    parentLi.classList.add('content-services__sidebar--open');                
                }
            });
        });
        let mobileToggle = document.querySelectorAll('.content-services__sidebar__mobile-parent .btn__icon');
        mobileToggle.forEach(function (e) {
            e.addEventListener('click', function(e){
                let parentLi = e.target.parentElement;
                if (parentLi.classList.contains('content-services__sidebar--mobile-open')) {
                    parentLi.classList.remove('content-services__sidebar--mobile-open');
                    parentLi.classList.add('content-services__sidebar--mobile-closed');
                    // Close all sub menus when main closes
                    let menuToggles = document.querySelectorAll('.content-services__sidebar__parent');
                    menuToggles.forEach(function (el) {
                        el.classList.remove('content-services__sidebar--open');
                        el.classList.add('content-services__sidebar--closed');
                    })
                } else {
                    parentLi.classList.remove('content-services__sidebar--mobile-closed');
                    parentLi.classList.add('content-services__sidebar--mobile-open');                
                }
            });
        });
    }
    const init = () => {
        if ( ! jQuery('.content-services__sidebar').length ) {
            return;
        }

        sidebar();
    };
    
    return init();
};