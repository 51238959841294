const ContentHomeSolutionsCards = (e) => {

    const backgroundResize = () => {
        var background = jQuery('.content-home-hero__outer-wrap');
        // clear any previous changes to background height
        background.css('height','');
        var backgroundHeight = background.height();
        // console.log('bh ' + backgroundHeight);

        var cardsWrap = jQuery('.content-home-solution-cards__outer-wrap');

        var cardsHeight = jQuery(cardsWrap).height();
        // console.log('cardsh ' + cardsHeight);

        var lastCardHeight = jQuery('.content-home-solution-cards__card').last().outerHeight();
        // console.log('lasth' + lastCardHeight);

        var fromBottom = (lastCardHeight / 2) - 50; // 50 to give a little more offset

        var totalHeight = (backgroundHeight + cardsHeight) - fromBottom;

        background.height(totalHeight);
        cardsWrap.css('top', -(cardsHeight - fromBottom) + 'px');
        cardsWrap.parent().css('margin-bottom', -(cardsHeight - fromBottom) + 'px');

        // On mobile and tablet, height of hero background is one half the bottom of the lowest card
    };
    
    const init = () => {
        backgroundResize();
    };
    
    return init();
};