/**
 * site
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

// log('some var we need to check');

const siteInit = () => {
	SearchSiteDefault();
	MobileMenu();
	MenuCta();
	SolutionsMenu();
	CardSlider();
	SolutionsSingleServicesNav();
	ContentHomeHero();
	ContentHomeExpertiseCards();
	ContentHomeSolutionsCards();
	// ContentHomeWhatWeDo();
	// ContentHomeSolutions();
	// ContentHomeSolutionsMenuSlider();
	ContentPortfolioHero();
	ContentPortfolioArchivedCaseStudies();
	SinglePortfolioRelevantCaseStudies();
	// ContentSolutions();
	ContentServices();
	ContentSolutionsInternal();
	ContentPageSingle();
	ContentBlogAjax();
};

var debounceActions = function (e) {
	ContentHomeSolutionsCards(e);
	ContentPageSingle(e);
	ContentServicesDebounce();
	ContentBuilderCtaJewelDebounce();
	// ContentHomeSolutionsMenuSlider();
	// If a hash is present in URL, scroll to it
	var hash = window.location.hash;
	if (hash.length > 0) {
		var element = document.querySelector(hash);
		var topPos = element.getBoundingClientRect().top + window.pageYOffset;

		window.scrollTo({
			top: topPos, // scroll so that the element is at the top of the view
			behavior: 'smooth' // smooth scroll
		});
	}
};
var debounceCallable = debounce(debounceActions, 250);

jQuery(document).ready(function () {
	siteInit();
	debounceActions();
	// If a hash is clicked, scroll to it and add it to history
	jQuery(function() {
		jQuery('a[href*=\\#]:not([href=\\#])').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				// console.log('pathname ' + location.pathname);
				// console.log('href ' + location.href);
				// console.log('location hash ' + location.hash);
				var locationString = "#" + jQuery(this).data("hash");
				// Does the clicked hash match the current page state
				if (locationString != location.hash) {
					var clickedHash = locationString;
				} else {
					var clickedHash = location.hash;
				}
				// console.log('clicked hash ' + clickedHash);

				// Does the current history state have a hash
				if (!location.hash.length) {
					var clickedURL = location.href + clickedHash;
				} else if ((location.href + clickedHash) != location.href) {
					var clickedURL = location.href.replace(location.hash,"") + clickedHash;
				} else {
					var clickedURL = location.href;
				}
				// console.log('clicked URL ' + clickedURL);

				var target = jQuery(this.hash);
				target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					// Scroll 
					jQuery('html,body').animate({
					scrollTop: target.offset().top
					}, 1000);
					// Add resulting hash to history
					history.pushState(null, null, clickedURL);
					return false;
				}
			}
		});
	  });
	  
});

jQuery(window).on( "resize", debounceCallable );
