const ContentBlogAjax = (e) => {
    if (!jQuery('body').hasClass('blog')) {
        return;
    }

    const blogAjax = () => {
        // console.log('blog ajax');
        // var triggers = document.getElementsByClassName('blog-ajax-waypoint');
        // for (let trigger of triggers) {
        //     var waypoint = new Waypoint({
        //         element: trigger,
        //         handler: function(direction) {
        //             if(direction == 'down') {
        //                 console.log('fire');
        //                 jQuery.ajax({
        //                     type : "post",
        //                     dataType : "json",
        //                     url : siteObject.ajaxurl,
        //                     data : {
        //                         action: "load_blog_ajax",
        //                         // blogAjaxIds is loaded via content-blog-landing-featured.php
        //                         blogAjaxIds : blogAjaxIds
        //                     },
        //                     success: function(response) {
        //                        if(response.type == "success") {
        //                           jQuery(".content-blog-landing-featured__post-list__posts .cards").append(response.html);
        //                        }
        //                        else {
        //                           alert("fail");
        //                        }
        //                     }
        //                  });
        //             }
        //         }
        //     });
        // };
    };
    
    const init = () => {
        blogAjax();
    };
    
    return init();
};