const SolutionsMenu = () => {

    const appendIcon = () => {

        let icon = siteObject.carrot;
        if ( jQuery(document).width() >= 768 ) {
            icon = siteObject.triangle;
        }

        jQuery('.content-solutions-nav__menu-desktop, .content-solutions-nav__menu-mobile-menu').find('li.menu-item-has-children').each(function() {
            const li = jQuery(this);
            if ( li.parent().hasClass('menu') ) {
                jQuery('a', li).first().append(`<span class="btn__icon">${icon}</span>`);
            }
        });
    };

    const togglePrimaryMenu = () => {
        jQuery(this).toggleClass('show');
        jQuery('.content-solutions-nav__menu-mobile').toggleClass('show');
        jQuery('.content-solutions-nav__menu-mobile-page-cover').toggleClass('show');
        jQuery('body').toggleClass('pause-scrolling');
    };

    const triggerTogglePrimaryMenu = () => {
        jQuery('.content-solutions-nav__menu-mobile-curent-page').click(function(e) {
            e.preventDefault();
            jQuery(this).toggleClass('show');
            togglePrimaryMenu();
        });
    };

    const showSubMenu = () => {
        jQuery('.content-solutions-nav__menu-mobile-menu li.menu-item-has-children a').click(function(e) {
            e.preventDefault();

            if ( jQuery(this).parent().parent().hasClass('sub-menu') ) {
                togglePrimaryMenu();
                jQuery('html, body').animate({
                    scrollTop: jQuery(jQuery(this).attr('href')).offset().top
                }, 50);
                return;
            }
            
            const linkText = jQuery(this).text();
            jQuery('.content-solutions-nav__menu-mobile-parent-page').addClass('show');
            jQuery('.content-solutions-nav__menu-mobile-parent-page .btn__text').html(linkText);
            jQuery('.content-solutions-nav__menu-mobile-menu').addClass('show-sub-menu');
            jQuery(this).parent().addClass('show-sub-menu');
        });
    };

    const hideSubMenu = () => {
        jQuery('.content-solutions-nav__menu-mobile-parent-page').removeClass('show');
        jQuery('.content-solutions-nav__menu-mobile-menu li.menu-item-has-children').removeClass('show-sub-menu');
        jQuery('.content-solutions-nav__menu-mobile-menu').removeClass('show-sub-menu');
    }

    const triggerHideSubMenu = () => {
        jQuery('.content-solutions-nav__menu-mobile-parent-page').click(function(e) {
            e.preventDefault();
            hideSubMenu();
        });
    }

    const setInitialState = () => {
        if ( ! jQuery('body').hasClass('page-template-tpl-solutions-single') ) {
            return;
        }

        const link = jQuery('.content-solutions-nav__menu-mobile-menu li.current-menu-item.menu-item-has-children a');
        jQuery('.content-solutions-nav__menu-mobile-parent-page').addClass('show');
        jQuery('.content-solutions-nav__menu-mobile-parent-page .btn__text').html(siteObject.page_title);
        jQuery('.content-solutions-nav__menu-mobile-menu').addClass('show-sub-menu');
        jQuery(link).parent().addClass('show-sub-menu');
    };

    const toggleSticky = () => {

        if ( ! jQuery('.content-solutions-nav__menu-mobile').hasClass('content-solutions-nav__menu-mobile') ) {
            return;
        }
        
        if ( document.documentElement.scrollTop > 68 ) {
            jQuery('.content-solutions-nav__menu-mobile-curent-page, .content-solutions-nav__menu-mobile').toggleClass('stick');
        }
        
        const currWaypoint = new Waypoint({
            element: '.content-solutions-nav__menu-mobile-curent-page',
            handler: function(direction) {

                switch ( direction ) {
                    case 'up':
                        setTimeout(function() {
                            jQuery('.content-solutions-nav__menu-mobile-curent-page, .content-solutions-nav__menu-mobile').toggleClass('stick');
                        }, 1);
                        break;
                    case 'down':
                        setTimeout(function() {
                            jQuery('.content-solutions-nav__menu-mobile-curent-page, .content-solutions-nav__menu-mobile').toggleClass('stick');
                        }, 1);
                        break;
                }
            }
        });

    };
    
    const init = () => {
        appendIcon();
        triggerTogglePrimaryMenu();
        showSubMenu();
        triggerHideSubMenu();
        setInitialState();
        toggleSticky();
    };
    
    return init();
};