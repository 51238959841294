const ContentHomeExpertiseCards = () => {

    const slider = () => {

        const tnsSlider = tns({
            mode: 'carousel',
            container: '.content-home-expertise-cards__cards',
            items: 1,
            loop: true,
            autoplay: false,
            // autoplayHoverPause: true,
            // autoplayTimeout: 0,
            autoplayButtonOutput: false,
            //preventActionWhenRunning: true,
            mouseDrag: false,
            nav: false,
            //navPosition: 'top',
            controls: false,
            edgePadding: 0,
            gutter: 0,
            center: false,
            speed: 2000,
            responsive: {
                769: {
                    autoplay: false,
                }
            }
        });

        const navButtons = document.querySelectorAll('.content-home-expertise-cards__nav button');

        const clearButtons = () => {
            navButtons.forEach( (i) => {
                i.classList.remove('active');
            }) 
        };

        document.querySelector('.content-home-expertise-cards__nav--in-geek').onclick = function () {
            tnsSlider.goTo(0);
            
            // update style based on index
            clearButtons();
            this.classList.add('active');
        };
        
        document.querySelector('.content-home-expertise-cards__nav--human').onclick = function () {

            tnsSlider.goTo(1);
            
            // update style based on index
            clearButtons();
            this.classList.add('active');
        };

        const tilt = jQuery('.tns-slide-active .content-home-expertise-cards__card').tilt({
            scale: 1.1,
            maxTilt: 30,
        });
    };
    
    const init = () => {

        if ( ! jQuery('.content-home-expertise-cards__cards').length ) {
            return;
        }

        slider();


        
    };
    
    return init();
};