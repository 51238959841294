const MobileMenu = () => {

    const appendIcon = () => {
        jQuery('.section-header__menu-mobile').find('li').each(function() {
            const li = jQuery(this);
            const linkText = jQuery('a', li).text();
            const textSpan = `<span class="btn__text">${linkText}</span>`;
            const iconSpan = `<span class="btn__icon">${siteObject.triangle}</span>`;
            jQuery('a', li).html(textSpan + iconSpan);
        });
    };
    
    const toggleMenu = () => {
        jQuery('#mobile-menu-trigger').click(function(e) {
            e.preventDefault();
            jQuery('.section-header-mobile').toggleClass('show');
            jQuery('.section-menu-mobile-drawer').toggleClass('show');
            jQuery('body').toggleClass('pause-scrolling');
        });
    };
    
    const init = () => {
        appendIcon();
        toggleMenu();
    };
    
    return init();
};